import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import {
    useProfile,
    UseQueryReturn,
    ProfilesQueryParams,
    SearchUsersResponse,
    Project,
    Questionnaire,
    getQuestionnaireAnswers,
    QuestionnaireAnswerState,
    Profile,
    updateProfile,
} from 'mushin-redux-store';
import { openAlert, openModal } from '../../Redux/reducers/modalsSlice';
import UserCriteria from '../UserCriteria/UserCriteria';
import Badge from '../Badge/Badge';
import DataGrid from '../DataGrid/DataGrid';
import { useAppDispatch } from '../../Helpers/hooks';
import { getNameToDisplay } from '../../Helpers/users';
import { DataGridColumn } from '../DataGrid/types';
import MuIcon from '../MuIcon';
import Popover from '../Popover/Popover';
import QuestionnairePreview from '../../Scenes/QuestionnairePreview/QuestionnairePreview';
import { isQuestionnaireAnswered } from '../../Helpers/questionnaires';
import { formatPastDateTime } from '../../Helpers/date';

type Props = {
    project: Project;
    questionnaire: Questionnaire;
    query: UseQueryReturn<ProfilesQueryParams, SearchUsersResponse>;
    selectedIds: string[];
    setSelectedIds: (ids: string[]) => void;
    getStatus: (user: Profile) => { mod: string; label: string; date?: string };
    scrollRef?: (node: HTMLDivElement) => void;
};

const RespondentsGrid: React.FC<Props> = ({
    questionnaire,
    query,
    selectedIds,
    setSelectedIds,
    getStatus,
    scrollRef,
}) => {
    const [previewId, setPreviewId] = useState<string>();
    const [questionnaireAnswer, setQuestionnaireAnswer] = useState<NonNullable<QuestionnaireAnswerState>>();

    const candidacyQuestionnaire = questionnaire?.candidacy;

    const dispatch = useAppDispatch();

    const getAnswers = useCallback(async () => {
        if (!previewId) return;
        setQuestionnaireAnswer(await getQuestionnaireAnswers(questionnaire.id, previewId));
    }, [previewId, questionnaire.id]);

    useEffect(() => {
        getAnswers().then();
    }, [getAnswers]);

    const handleClosePreview = () => {
        setPreviewId(undefined);
        setQuestionnaireAnswer(undefined);
    };

    const columns: DataGridColumn<Profile>[] = [
        {
            key: 'firstname',
            header: i18n.t('global.name'),
            component: ({ item }) => <strong>{getNameToDisplay(item)}</strong>,
            classModifiers: 'link',
            onClick: (item) => dispatch(openModal('ContributorDetails', { profileId: item.id })),
            size: '1fr',
        },
        {
            key: 'email',
            header: i18n.t('models.users.email'),
            component: ({ item }) =>
                item.user?.email ? <strong>{item.user.email}</strong> : <em>{item.user?.anonymousEmail}</em>,
            size: '1fr',
        },
        {
            key: 'criteria',
            header: i18n.t('criteria.items'),
            component: ({ item }) => <UserCriteria profile={item} limit />,
            size: '2fr',
        },
        {
            key: 'status',
            header: i18n.t('global.status.status'),
            component: ({ item }) => {
                const status = getStatus(item);
                return (
                    <>
                        <Badge classModifiers={status.mod}>{i18n.t(status.label)}</Badge>
                        {status.date && (
                            <span className="mu-questionnaire-respondents__datetime">
                                {formatPastDateTime(status.date)}
                            </span>
                        )}
                    </>
                );
            },
            size: '1fr',
        },
        {
            key: 'action',
            header: i18n.t('global.action'),
            component: ({ item }) => {
                const isAccepted = !!item.accepted_at;
                const answered = isQuestionnaireAnswered(questionnaire, item.user?.id || '');

                return (
                    <div className="mu-questionnaire-respondents__action">
                        {answered && (
                            <Popover
                                position="bottom"
                                hoveredElement={
                                    <button
                                        className="mu-questionnaire-respondents__item"
                                        type="button"
                                        onClick={() => setPreviewId(item.id)}
                                    >
                                        <MuIcon svgName="Survey" />
                                    </button>
                                }
                            >
                                {i18n.t('questionnaires.seeAnswers')}
                            </Popover>
                        )}
                        {!answered && !candidacyQuestionnaire && item.user?.id && (
                            <Popover
                                position="bottom"
                                hoveredElement={
                                    <button
                                        className="mu-questionnaire-respondents__item"
                                        type="button"
                                        onClick={() =>
                                            dispatch(
                                                openModal('CreateReminder', {
                                                    profileId: item.id,
                                                    missionId: questionnaire.id,
                                                }),
                                            )
                                        }
                                    >
                                        <MuIcon svgName="Bell" />
                                    </button>
                                }
                            >
                                {i18n.t('global.remind')}
                            </Popover>
                        )}
                        {candidacyQuestionnaire &&
                            answered &&
                            !isAccepted &&
                            !!(item.user?.confirmed_at || item.user?.email) && (
                                <Popover
                                    position="bottom"
                                    hoveredElement={
                                        <button
                                            className="mu-questionnaire-respondents__item"
                                            type="button"
                                            onClick={() => {
                                                dispatch(updateProfile(item.id, { accepted: true }));
                                            }}
                                        >
                                            <MuIcon svgName="Check" />
                                        </button>
                                    }
                                >
                                    {i18n.t('respondents.acceptTheRespondent')}
                                </Popover>
                            )}
                        {candidacyQuestionnaire && answered && !isAccepted && !item.refused_at && (
                            <Popover
                                position="bottom"
                                hoveredElement={
                                    <button
                                        className="mu-questionnaire-respondents__item"
                                        type="button"
                                        onClick={() => {
                                            dispatch(updateProfile(item.id, { refused: true }));
                                        }}
                                    >
                                        <MuIcon svgName="Close" />
                                    </button>
                                }
                            >
                                {i18n.t('respondents.refuseTheRespondent')}
                            </Popover>
                        )}
                        {!isAccepted && item.refused_at && (
                            <Popover
                                position="bottom"
                                hoveredElement={
                                    <button
                                        className="mu-questionnaire-respondents__item"
                                        type="button"
                                        onClick={() => {
                                            dispatch(
                                                openAlert({
                                                    type: 'danger',
                                                    title: i18n.t('modals.deleteRespondent.title'),
                                                    icon: 'Delete',
                                                    confirmLabel: i18n.t('modals.deleteRespondent.confirmButton'),
                                                    body: i18n.t('modals.deleteRespondent.catchPhrase'),
                                                    confirmAction: async () => {
                                                        /* TODO handle profile delete */
                                                    },
                                                }),
                                            );
                                        }}
                                    >
                                        <MuIcon svgName="Delete" />
                                    </button>
                                }
                            >
                                {i18n.t('respondents.deleteTheRespondent')}
                            </Popover>
                        )}
                    </div>
                );
            },
            size: '1fr',
        },
    ];

    return (
        <>
            <DataGrid
                columns={columns}
                data={query.results || []}
                getItem={useProfile}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                totalItems={query.pager?.total_items}
                getAllItems={query.getAllResults}
                standalone
                withScroll
                scrollRef={scrollRef}
            />
            {questionnaire && !!questionnaireAnswer && (
                <div className="mu-edit-questionnaire__preview">
                    <button type="button" className="mu-edit-questionnaire__preview-close" onClick={handleClosePreview}>
                        <MuIcon svgName="Close" />
                    </button>
                    <QuestionnairePreview questionnaire={questionnaire} answers={questionnaireAnswer} disabled />
                </div>
            )}
        </>
    );
};

export default RespondentsGrid;
