import React from 'react';
import i18n from 'i18next';
import { Questionnaire } from 'mushin-redux-store';
import StatusSelectButton from './StatusSelectButton';
import { classWithModifiers } from '../../../../Helpers/css';

export const respondentStatusLabelKeys = {
    accepted: 'global.accepted',
    refused: 'global.refused',
    toBeProcessed: 'global.toBeProcessed',
    answered: 'global.answered',
    reminded: 'global.reminded',
    anonymous: 'models.users.anonymous',
    invited: 'global.invited',
};

type Props = {
    status: string | undefined;
    setStatus: (status: string | undefined) => void;
    questionnaire?: Questionnaire;
};

const RespondentStatusDropdownPanel: React.FC<Props> = ({ status, setStatus, questionnaire }) => (
    <>
        <button
            className={classWithModifiers('mu-dropdown-menu__button-item', { selected: !status })}
            type="button"
            onClick={() => setStatus(undefined)}
        >
            {i18n.t('tooltips.all')}
        </button>
        {questionnaire?.candidacy ? (
            <>
                <StatusSelectButton
                    status="accepted"
                    setStatus={setStatus}
                    labelKey={respondentStatusLabelKeys.accepted}
                    modifier="valid"
                    selected={status === 'accepted'}
                />
                <StatusSelectButton
                    status="toBeProcessed"
                    setStatus={setStatus}
                    labelKey={respondentStatusLabelKeys.toBeProcessed}
                    modifier="info"
                    selected={status === 'toBeProcessed'}
                />
                <StatusSelectButton
                    status="refused"
                    setStatus={setStatus}
                    labelKey={respondentStatusLabelKeys.refused}
                    modifier="warning"
                    selected={status === 'refused'}
                />
                <StatusSelectButton
                    status="anonymous"
                    setStatus={setStatus}
                    labelKey={respondentStatusLabelKeys.anonymous}
                    selected={status === 'anonymous'}
                />
                <StatusSelectButton
                    status="invited"
                    setStatus={setStatus}
                    labelKey={respondentStatusLabelKeys.invited}
                    selected={status === 'invited'}
                />
            </>
        ) : (
            <>
                <StatusSelectButton
                    status="answered"
                    setStatus={setStatus}
                    labelKey={respondentStatusLabelKeys.answered}
                    modifier="valid"
                    selected={status === 'answered'}
                />
                <StatusSelectButton
                    status="reminded"
                    setStatus={setStatus}
                    labelKey={respondentStatusLabelKeys.reminded}
                    modifier="info"
                    selected={status === 'reminded'}
                />
                <StatusSelectButton
                    status="invited"
                    setStatus={setStatus}
                    labelKey={respondentStatusLabelKeys.invited}
                    modifier="warning"
                    selected={status === 'invited'}
                />
            </>
        )}
    </>
);

export default RespondentStatusDropdownPanel;
