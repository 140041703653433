import React, { useEffect, useState } from 'react';
import { useCurrentProject, useQuestionnaire, ProfilesQueryParams, useProfilesQuery } from 'mushin-redux-store';
import i18n from 'i18next';
import { useParams } from 'react-router-dom';
import { useScrollThreshold } from '../../Helpers/effects';
import { useSearchParams } from '../../Helpers/queryParser';
import { getRespondentStatus } from '../../Helpers/users';
import AppLayout from '../../Components/Layouts/AppLayout';
import RespondentToolbar, { RespondentsSearchParams } from '../../Components/RespondentToolbar/RespondentToolbar';
import RespondentsGrid from '../../Components/RespondentsGrid/RespondentsGrid';

const searchToQueryParams = (searchParams: RespondentsSearchParams): ProfilesQueryParams => {
    const { status, ...queryParams } = searchParams;

    (queryParams as ProfilesQueryParams).accepted = '';

    if (status === 'accepted') {
        (queryParams as ProfilesQueryParams).accepted = true;
    } else if (status === 'toBeProcessed') {
        (queryParams as ProfilesQueryParams).accepted = false;
    } else if (status === 'refused') {
        (queryParams as ProfilesQueryParams).refused = true;
    } else if (status === 'anonymous') {
        (queryParams as ProfilesQueryParams).questionnaire_response_status = 'answered';
        (queryParams as ProfilesQueryParams).verified = false;
    } else if (status === 'invited') {
        (queryParams as ProfilesQueryParams).questionnaire_response_status = 'invited';
        (queryParams as ProfilesQueryParams).verified = undefined;
    } else if (status) {
        (queryParams as ProfilesQueryParams).questionnaire_response_status = status;
    }

    return queryParams;
};

const searchToDurable = (searchParams: RespondentsSearchParams) => {
    const params = { ...searchParams };
    return !Object.keys(params).length;
};

const QuestionnaireRespondents: React.FC = () => {
    const { questionnaireId } = useParams<{ projectId: string; questionnaireId: string }>();

    const [searchParams, setSearchParams] = useSearchParams({ groups: 'array' });
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const questionnaire = useQuestionnaire(questionnaireId);
    const project = useCurrentProject();
    const profilesQuery = useProfilesQuery(
        {
            type: 'contributor',
            questionnaire_id: questionnaireId,
            questionnaire_response_status: questionnaire?.candidacy ? 'answered' : undefined,
            verified: true,
            ...searchToQueryParams(searchParams),
        },
        searchToDurable(searchParams),
    );

    const ref = useScrollThreshold(profilesQuery.getMoreResults, { deps: [profilesQuery.results?.length] });

    const { setParams } = profilesQuery;
    useEffect(() => {
        setParams(
            {
                type: 'contributor',
                questionnaire_id: questionnaireId,
                questionnaire_response_status: questionnaire?.candidacy ? 'answered' : undefined,
                verified: true,
                ...searchToQueryParams(searchParams),
            },
            searchToDurable(searchParams),
        );
    }, [searchParams, setParams, questionnaireId, questionnaire?.candidacy]);

    if (!project) {
        return null;
    }

    return (
        <AppLayout
            title={i18n.t('respondents.list')}
            backLink={
                questionnaire?.candidacy
                    ? `/projects/${project.id}/members?is_contributor=true`
                    : `/projects/${project?.id}/missions`
            }
        >
            {!!questionnaire && (
                <>
                    <div className="mu-questionnaire-respondents__toolbar">
                        <RespondentToolbar
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            queryParams={profilesQuery.params}
                            projectId={project.id}
                            selectedIds={selectedIds}
                            questionnaire={questionnaire ?? undefined}
                            getStatus={(user) => getRespondentStatus(user, questionnaire, project)}
                        />
                    </div>
                    <RespondentsGrid
                        project={project}
                        questionnaire={questionnaire}
                        scrollRef={ref}
                        query={profilesQuery}
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                        getStatus={(user) => getRespondentStatus(user, questionnaire, project)}
                    />
                </>
            )}
        </AppLayout>
    );
};

export default QuestionnaireRespondents;
