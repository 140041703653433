import { Album, Profile, Project, Questionnaire, useProfile } from 'mushin-redux-store';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import Client from '../Services/Client';
import { useAppSelector } from './hooks';

export const getProfileStatus = (profile: Profile): { mod: string; label: string; date?: string } => {
    if (profile.metrics.first_activity_at) {
        return {
            mod: 'valid',
            label: 'global.active',
            date: profile.user?.confirmed_at || profile.invited_at,
        };
    }

    if (profile.user?.confirmed_at) {
        return { mod: 'info', label: 'models.users.registered', date: profile.user?.confirmed_at };
    }

    if (profile.invited_at) {
        return { mod: 'warning', label: 'global.invited', date: profile.invited_at };
    }

    return {
        mod: 'default',
        label: 'global.invited',
        date: undefined,
    };
};

export const getRespondentStatus = (
    profile: Profile,
    questionnaire: Questionnaire,
    project?: Project | null,
): { mod: string; label: string; date?: string } => {
    const participant = questionnaire.participants.find((p) => p.id === profile.user?.id);

    if (questionnaire.candidacy && project) {
        const date = profile.refused_at || profile.accepted_at || profile.invited_at;
        if (profile.refused_at) {
            return { mod: 'warning', label: 'global.refused', date };
        }
        if (profile.accepted_at) {
            return { mod: 'valid', label: 'global.accepted', date };
        }
        if (profile.user?.email_verified) {
            return { mod: 'info', label: 'global.toBeProcessed', date };
        }
        if (participant?.completed_at) {
            return { mod: '', label: 'models.users.anonymous', date: participant.completed_at };
        }
        return { mod: '', label: 'global.invited', date };
    }

    if (participant?.completed_at) {
        return { mod: 'valid', label: 'global.answered', date: participant.completed_at };
    }
    if (participant?.started_at) {
        return { mod: 'valid', label: 'global.status.inProgress', date: participant.started_at };
    }

    if (participant?.reminded) {
        return { mod: 'info', label: 'global.reminded', date: participant.reminded.last };
    }

    return { mod: 'warning', label: 'global.invited', date: participant?.invited_at };
};

export const isProjectAdmin = (project: Project): boolean => {
    if (Client.isAllowedToEditOrganization()) return true;

    const role = project.profile?.role;
    return role === 'owner' || role === 'admin';
};
export const isContextAdmin = (project?: Project | null): boolean => {
    if (project) return isProjectAdmin(project);
    return Client.isAllowedToEditOrganization();
};
export const getProjectContributorAddedTime = (project: Project): string | undefined => {
    return project.contributors?.find((contributor) => contributor.id === Client.getId())?.invited_at;
};
export const isProjectContributor = (project: Project): boolean => {
    return project.profile?.type === 'contributor';
};
export const isContributor = (project?: Project | null): boolean => {
    if (project) return isProjectContributor(project);
    return Client.isOrganizationContributor();
};

export const isAlbumAdmin = (album: Album): boolean => {
    return (
        Client.isAllowedToEditOrganization() ||
        album.projectMembers.find((member) => member.id === Client.getId())?.role !== 'regular' ||
        album.members.find((member) => member.id === Client.getId())?.role !== 'regular'
    );
};

export const getNameToDisplay = (profile: Profile | null | undefined): string => {
    if (!profile) return '';
    if (!profile.name) return i18n.t('models.users.anonymous');
    return profile.name;
};

export const getProfileAvatar = (profile: Profile): string =>
    profile.user?.avatar_url || '/assets/images/defaultAvatar.png';

export const useProfileWithDefault = (id: string | undefined): Profile | null | undefined => {
    const { t } = useTranslation();

    const defaultUser = useMemo((): Profile | undefined => {
        if (!id) return undefined;

        const fakeDate = new Date().toISOString();

        const firstname = t('users.default.firstname') as string;
        const lastname = t('users.default.lastname') as string;
        return {
            id,
            organization_id: '',
            confidential: true,
            user: null,
            type: 'contributor',
            name: `${firstname} ${lastname}`,
            created_at: fakeDate,
            updated_at: fakeDate,
            accepted_at: fakeDate,
            invited_at: fakeDate,
            invited_by: '',
            criteria: [],
            metrics: {},
        };
    }, [id, t]);

    const member = useProfile(id);

    return member === null ? defaultUser : member;
};

export const useRedirectIfLoggedIn = (url = '/'): void => {
    const isConnected = useAppSelector((state) => state.auth.isConnected);
    const navigate = useNavigate();

    useEffect(() => {
        if (isConnected) {
            navigate(url);
        }
    }, [isConnected, navigate, url]);
};

export const useRedirectIfConfirmed = (url = '/'): void => {
    const isConfirmed = useAppSelector((state) => !!state.auth.user?.confirmed_at);
    const navigate = useNavigate();

    useEffect(() => {
        if (isConfirmed) {
            navigate(url);
        }
    }, [isConfirmed, navigate, url]);
};
