import { useEffect, useState } from 'react';
import { AuthResponse, AvailableLocale, generateCodeRequest, validateCodeRequest } from 'mushin-redux-store';
import i18n from 'i18next';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useAppDispatch } from '../../../Helpers/hooks';
import { getErrorKey } from '../../../Helpers/errors';

export type UseCodeRequest = {
    countdown: number;
    error: string | null;
    send: (email: string) => Promise<boolean>;
    validate: (email: string, code: string) => Promise<AuthResponse | null>;
};

const ERRORS = {
    LOGIN_ACCOUNT_BLOCKED: 'accounts.login.blocked',
    LOGIN_MUST_USE_SSO: 'accounts.login.mustUseSSO',
    DEFAULT: 'accounts.reset_password.errors.unknown',
};

const useCodeRequest = (): UseCodeRequest => {
    const [countdown, setCountdown] = useState(0);
    const [error, setError] = useState<string | null>(null);

    const dispatch = useAppDispatch();

    const activeCountdown = !!countdown;
    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (activeCountdown) {
            interval = setInterval(() => {
                setCountdown((prevValue) => prevValue - 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [activeCountdown]);

    const send = async (email: string) => {
        try {
            setError(null);
            await generateCodeRequest(email.trim(), i18n.resolvedLanguage as AvailableLocale);
            setCountdown(30);
            return true;
        } catch (err) {
            Sentry.captureException(err, (scope) => {
                scope.setContext('codeRequest', { email });
                return scope;
            });
            setError(getErrorKey(err, ERRORS));
        }
        return false;
    };

    const validate = async (email: string, code: string) => {
        try {
            setError(null);
            return await dispatch(validateCodeRequest(email.trim(), code));
        } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status === 401) {
                setError('accounts.login.confirmation.wrongCode');
            } else {
                Sentry.captureException(err, (scope) => {
                    scope.setContext('createAccount', { email });
                    return scope;
                });
                setError('accounts.reset_password.errors.unknown');
            }
        }
        return null;
    };

    return {
        countdown,
        error,
        send,
        validate,
    };
};

export default useCodeRequest;
