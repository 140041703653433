import React from 'react';
import i18n from 'i18next';
import Badge from '../../../Badge/Badge';
import { classWithModifiers } from '../../../../Helpers/css';

type Props = {
    status: string;
    setStatus: (status: string) => void;
    labelKey: string;
    modifier?: 'warning' | 'valid' | 'finished' | 'archived' | 'info';
    selected?: boolean;
};

const StatusSelectButton: React.FC<Props> = ({ status, setStatus, labelKey, modifier, selected = false }) => (
    <button
        className={classWithModifiers('mu-dropdown-menu__button-item', { selected })}
        type="button"
        onClick={() => setStatus(status)}
    >
        <Badge classModifiers={['button', modifier, selected && 'selected']}>{i18n.t(labelKey)}</Badge>
    </button>
);

export default StatusSelectButton;
